import { PersistenceLevel, Store } from '@/core/flux.service';
import _ from 'lodash';
import { ViewDateRange } from '@/vantage/types/DateTime.types';
import { SimplifiedNode } from '@/vantage/types/SimplifedNode.types';
import { VantageNames } from '@/vantage/constants';

/**
 * Default filters for the grid
 * @exposedfortesting
 */
export const defaultFilters = {
  [VantageNames.DerivedColumns.Extinct]: { filterType: 'set', values: ['False'] },
};

export class VantageTableStore extends Store {
  persistenceLevel: PersistenceLevel = 'WORKSHEET';
  static readonly storeName = 'sqVantageTableStore';

  initialize(): void {
    this.state = this.immutable({
      selection: [],
      gridState: { filter: defaultFilters },
      quickFilter: '',
      dateRange: {
        lookBack: { num: 7, unit: { value: 'day', label: 'day' } },
        lookForward: { num: 0, unit: { value: 'day', label: 'day' } },
        range: [new Date(new Date().valueOf() - 7 * 24 * 60 * 60 * 1000).toISOString(), new Date().toISOString()],
      },
      dateTimeRange: this.monkey(['dateRange'], (dateRange: ViewDateRange) => [
        new Date(dateRange.range?.[0] as Date),
        new Date(dateRange.range?.[1] as Date),
      ]),
    });
  }

  get selection(): SimplifiedNode[] {
    return this.state.get('selection');
  }

  get gridState(): any {
    return this.state.get('gridState');
  }

  get quickFilter(): string {
    return this.state.get('quickFilter');
  }

  get dateRange(): ViewDateRange {
    return this.state.get('dateRange');
  }

  get dateTimeRange(): [Date, Date] {
    return this.state.get('dateTimeRange');
  }

  dehydrate() {
    return _.omit(this.state.serialize(), 'selection', 'dateTimeRange');
  }

  rehydrate(dehydratedState: any) {
    this.state.deepMerge(dehydratedState);
  }

  protected readonly handlers = {
    SET_VANTAGE_SELECTION: (payload: { selection: SimplifiedNode[] }) => this.state.set('selection', payload.selection),
    SET_VANTAGE_GRID_STATE: ({ gridState }: { gridState: any }) => {
      const filter = { ...defaultFilters, ...gridState.filter };
      this.state.set('gridState', { ...gridState, filter });
    },
    SET_VANTAGE_QUICK_FILTER: (payload: { quickFilter: string }) => this.state.set('quickFilter', payload.quickFilter),
    SET_VANTAGE_DATE_RANGE: (payload: { dateRange: ViewDateRange }) => this.state.set('dateRange', payload.dateRange),
  };
}
